.mobileMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .mobileMenu {
    width: 100%;
    max-width: 24rem;
  }
}
